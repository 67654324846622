import './App.css';
import RTKMAPS from './map.js';

function App() {
  return (
    <div>
      <RTKMAPS />
    </div>
  )
}

export default App;
